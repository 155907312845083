.splash {
  @include flex();
  height: calc(100vh - 52px);
  background-color: $color-dark-grey;
  @include media-breakpoint-down(md) {
    height: calc(var(--vh, 1vh) * 100 - 54px);
  }

  &-title,
  &-subtitle {
    opacity: 0;
    color: $color-white;
    transition: 0.1s;
  }

  &-title {
    font-size: 72px;
    @include animate('fadeLeftToRight 1.5s forwards 1', '0.5s');
    @include media-breakpoint-down(md) {
      font-size: 48px;
    }
    @include media-breakpoint-down(sm) {
      font-size: 32px;
    }
  }
  &-subtitle {
    font-size: 32px;
    @include animate('fadeLeftToRight 1.5s forwards 1', '1.75s');
    @include media-breakpoint-down(md) {
      font-size: 24px;
    }
    @include media-breakpoint-down(sm) {
      font-size: 20px;
    }
  }

  &-content {
    text-align: center;
    @include flex();
  }

  &-avatar {
    z-index: 2;
    width: 200px;
    height: 200px;
    @include set-background-image('../../../public/logo.svg');
    @include media-breakpoint-down(sm) {
      width: 150px;
      height: 150px;
    }
  }
}

.bg-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @include set-background-image('../../assets/cover0.webp');
  @include filter(blur($blur-amount) brightness(0.5));
}
