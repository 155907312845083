.avatar {
  max-width: 200px;
  max-height: 200px;
  margin: auto;

  @include media-breakpoint-down(sm) {
    margin: 24px auto;
  }

  &-container {
    height: auto;
    display: flex;
    @include media-breakpoint-down(sm) {
      display: block;
    }
  }
}
