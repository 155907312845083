.external-link {
  text-decoration: none;
  &:hover {
    padding: 0 4px 1px 4px;
    border: 0px;
    border-radius: 4px;
  }

  .new-tab-icon {
    margin-bottom: 4px;
    width: 10px;
    height: 10px;
  }
}
