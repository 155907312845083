.form {
  &-button {
    margin-bottom: 0.25rem;
    margin-top: 0.75rem;
  }

  &-label {
    margin-bottom: 0.25rem;
    margin-top: 1rem;
  }
  &-control {
    &:valid {
      border-color: $color-valid;
    }
    &:invalid:not(:placeholder-shown) {
      border-color: $color-invalid;
    }
  }
}

.loader {
  border-color: $color-dark-grey;
  border-right-color: $color-accent;
}
