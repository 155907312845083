$blur-amount: 6px;
$color-accent: #1b6ea6;
$color-highlight: #2282c2;
$color-black: #111;
$color-dark-grey: #333;
$color-grey: #eee;
$color-white: #fff;
$color-valid: rgb(24, 160, 24);
$color-invalid: rgb(181, 0, 0);

$btn-color-pressed: #0067ab;
$grid-gutter-width: 0rem;