.show-more {
  @include flex();
  background-color: $color-white;
  border: none;
  cursor: pointer;
  font-size: 1.25rem;

  &-container {
    @include flex();
    position: inherit;
    height: 100%;
  }
}

.arrow {
  span {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: $color-accent;
    color: $color-white;
    transition-duration: 0.2s;
    font-size: 1.9rem;

    &:hover {
      background-color: $color-highlight;
    }
  }
}
