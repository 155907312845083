.card-container {
  justify-content: start;
  
  .card-wrapper {
    padding-right: 24px;
    @include flex($direction: row, $align: stretch);
  }
  
  .card {
    margin-bottom: 26px;
    @include animate('fadeIn 0.3s linear');
    &-extra {
      margin: 24px 0 12px;
      padding: 0 12px;
      &.react-spinner {
        @include animate('spin 5s linear infinite');
      }
    }
    &:hover {
      box-shadow: 0px 0px 2px 2px $color-grey;
    }
  }
}