.footer {
  margin-top: 60px;
  padding: 3rem 0;
  background-color: $color-dark-grey;
  text-align: center;
  
  &-text {
    color: $color-white;
    margin: 8px;
  }

  .logo {
    width: 56px;
    height: 56px;
  }  
}
