@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@include keyframes(spin) {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}

@include keyframes(fadeLeftToRight) {
  0% {
      transform: translateX(-20px);
      opacity: 0;
  }
  100% {
      transform: translateX(0);
      opacity: 1;
  }
}

@include keyframes(slideUp) {
  0% {
      bottom: -10%;
      opacity: 0;
  }
  100% {
      bottom: 0;
      opacity: 1;
  }
}

@include keyframes(fadeIn) {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
