@mixin filter($value) {
  -webkit-filter: $value;
     -moz-filter: $value;
       -o-filter: $value;
          filter: $value;
}

@mixin animate($str, $delay: 0s) {
  -webkit-animation: #{$str};
  animation: #{$str};
  -webkit-animation-delay: #{$delay};
  animation-delay: #{$delay};
}

@mixin flex($direction: column, $align: center, $just: center) {
	display: flex;
	flex-direction: $direction;
	align-items: $align;
	justify-content: $just;
}

@mixin set-background-image($url: '', $pos: center, $repeat: no-repeat, $size: cover) {
	background-image: url($url);
	background-position: $pos;
	background-repeat: $repeat;
	background-size: $size;
}

@mixin dark-mode-text {
  color: #d7d7d7 !important;
}

@mixin dark-mode-background {
  background-color: #1e2022 !important;
  .card {
    border-color: #353535;
  }
}
