nav {
  z-index: 1;
  top: 0;
  height: 49px;
  @include filter(drop-shadow(0 0mm 2mm rgb(65, 65, 65)));
  position: sticky;
  
  ul {
    list-style-type: none;
    overflow: hidden;
    background-color: $color-dark-grey;
    text-align: center;

    li {
      display: inline-block;
  
      a {
        display: block;
        padding: 14px 16px;
        color: $color-white;
        transition: 0.3s !important;
  
        &.active {
          background-color: $color-accent;
        }

        &:hover {
          background-color: $color-highlight;
          color: $white;
          &:not(.active) {
            background-color: $color-black;
          }
        }
      }
    }
  }
}
