*:not(.splash-content > *):not(.splash > *) {
  transition: 0.075s;
}

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: unset;
}

textarea {
  height: 5rem;
}

code {
  background-color: $color-grey;
  color: $color-dark-grey;
}

ul {
  margin: 0;
  padding: 0;
}

.content-div { 
  padding-top: 60px;
  padding-left: .5rem;
  padding-right: .5rem;
  margin-left: .5rem;
  margin-right: .5rem;
}

.dark-mode {
  &-text {
    @include dark-mode-text;
  }
  &-background {
    @include dark-mode-background;
  }
}

.row {
  justify-content: center;
}