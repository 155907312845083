.btn {
  &-submit {
    &.form-button {
      @extend .btn-primary;
      background-color: $color-accent;
      border-color: $color-accent;
      color: $color-white;

      &:hover {
        background-color: $color-highlight;
        border-color: $color-highlight;
      }

      &:focus {
        background-color: $btn-color-pressed;
        border-color: $btn-color-pressed;
      }
    }
  }
  &-nav {
    margin-left: 0.5rem;
    vertical-align: unset;
  }

  &--inline-link {
    padding: 0 0 5px 5px;
    color: $color-accent;
    border: none;

    &:hover {
      color: $color-highlight;
    }
  }
}